import Arrows from "../assets/Icons/Arrows.svg";
import {checkIsInputEmpty, setNetworkErrorMessage} from "../store/actions";
import {ErrorCodes, ErrorMessage} from "./ErrorConstants";
import {
    DEMAND_UNIT_OPTIONS_VALUE,
    FLEXIBLE_DEMAND_MAXIMUM_LIMIT,
    FLEXIBLE_DEMAND_MINIMUM_LIMIT,
    MAX_DEMAND_PER_HOUR,
    MIN_DEMAND_PER_HOUR,
    PER_HOUR_VALUE
} from "../pages/Order/constants";
import {NO_USER_ROLE_MESSAGE, PROFILE_NO_COMPANY_MESSAGE} from "../store/constants";
import {
    dateTimeDifference,
    getMaxDays,
    convertDateStrToDateObj,
    formatDateNoUTC,
    calculateDurationInHour,
    getUTCMinuteDifferenceForBRP
} from "./date-utils";
import {ERROR_PAGE, ORDER_PAGE, ORDER_TYPE, PRODUCTION_PLAN_PAGE, SETTINGS_PAGE} from "./RoutesConstants";
import {STATUS} from "../pages/OrderHistory/OrderHistoryConstant";
import {COLOR_INDICATOR} from "../pages/ProductionPlan/ProductionPlanConstant";
import {NOTIFICATION_TYPE, REGEX} from "./Constants";
import dayjs from "dayjs";

export const customUnSortedIcon = () => {
    return (
        <img
            id="filter"
            src={Arrows}
            alt="arrows"
            style={{width: "8px", height: "14px"}}
        />
    );
};

export const thousandSeperator = (amount) => {
    if (amount <= 10000) {
        return amount;
    } else {
        return amount.toLocaleString("en").replaceAll(",", " ");
    }
};

export const getProductionPerHour = (
    startDateTime,
    endDateTime,
    totalAmount,
    unit = DEMAND_UNIT_OPTIONS_VALUE.UNIT_PER_HOUR
) => {
    const diffHours = dateTimeDifference(startDateTime, endDateTime);
    let productionPerHour = Math.round(totalAmount / diffHours);
    if (unit === DEMAND_UNIT_OPTIONS_VALUE.UNIT_PER_DAY) {
        productionPerHour = Math.round((totalAmount / diffHours) * 24);
    }

    return productionPerHour;
};

export const calculateHydrogenFlowPerDay = (row) => {
    const timeDifference = dateTimeDifference(dayjs(row?.startOffTakeTime), dayjs(row?.endOffTakeTime)) / PER_HOUR_VALUE;
    const diffInDays = timeDifference < 1 ? 1 : timeDifference;
    return Math.round(row?.hydrogenDemand / diffInDays);
}

export const isNotFLCapGreaterThanMaxDemand = (isFlexibleDemand, capacity) =>
    !isFlexibleDemand && capacity > MAX_DEMAND_PER_HOUR;

export const isNotFLCapLessMinDemand = (isFlexibleDemand, capacity) =>
    !isFlexibleDemand && capacity < MIN_DEMAND_PER_HOUR;

export const isFLCapLessDemandML = (isFlexibleDemand, capacity) =>
    isFlexibleDemand && capacity < FLEXIBLE_DEMAND_MINIMUM_LIMIT;

export const isFLCapGreaterThanDemandML = (isFlexibleDemand, capacity) =>
    isFlexibleDemand && capacity > FLEXIBLE_DEMAND_MAXIMUM_LIMIT;

export const dateValidationForFlexibleDemand = (isFlexibleDemand, startDate, endDate) =>
    isFlexibleDemand && getMaxDays(startDate, 7) < convertDateStrToDateObj(endDate);

export const timeValidationForFlexibleDemand = (isFlexibleDemand, startDate, endDate, startDateTime, endDateTime) =>
    isFlexibleDemand && parseInt((formatDateNoUTC(endDateTime, "HH") - formatDateNoUTC(startDateTime, "HH"))) < 5 && startDate === endDate;

export const timeValidationForIndustrialOfftake = (isFlexibleDemand, startDate, endDate, startDateTime, endDateTime) =>
    !isFlexibleDemand && formatDateNoUTC(endDateTime, "HH") - formatDateNoUTC(startDateTime, "HH") < 2 && startDate === endDate;

export const timeValidationForIndustrialOfftakeMaxDays = (isFlexibleDemand, startDateTime, endDateTime) =>
    !isFlexibleDemand && calculateDurationInHour(startDateTime, endDateTime) > 720;

export const timeValidationForFlexibleDemandMaxDays = (isFlexibleDemand, startDateTime, endDateTime) =>
    isFlexibleDemand && calculateDurationInHour(startDateTime, endDateTime) > 168;

export const checkInputEmpty = ({
                                    startDate,
                                    startHour,
                                    endDate,
                                    endHour,
                                    id,
                                    totalAmount,
                                    dispatch,
                                }) => {
    if (!startDate) {
        dispatch(
            checkIsInputEmpty({
                isStartDateEmpty: true,
            })
        );
    }
    if (!startHour) {
        dispatch(
            checkIsInputEmpty({
                isStartHourEmpty: true,
            })
        );
    }
    if (!endDate) {
        dispatch(
            checkIsInputEmpty({
                isEndDateEmpty: true,
            })
        );
    }
    if (!endHour) {
        dispatch(
            checkIsInputEmpty({
                isEndHourEmpty: true,
            })
        );
    }
    if (!id) {
        dispatch(
            checkIsInputEmpty({
                isIdEmpty: true,
            })
        );
    }
    if (!totalAmount) {
        dispatch(
            checkIsInputEmpty({
                isCapacityEmpty: true,
            })
        );
    }
};

export const checkInput = (value, type) => {
    const patterns = {
        id: REGEX.ALPHANUMERIC_WITH_CHARACTER,
        staticCost: REGEX.NUMBER_WITH_SPECIAL_CHARACTER,
        company: REGEX.ALPHANUMERIC_WITHOUT_SPECIAL_CHARACTER,
        users: REGEX.ALPHABET_WITH_SPECIAL_CHARACTER,
        phoneNumber: REGEX.PHONE_NUMBER_INCLUDING_PLUS,
        numberOnly: REGEX.NUMBER,
        alphabetsWithSpecialCharacter: REGEX.ALPHANUMERIC_WITH_SPECIAL_CHARACTER
    };

    if (type in patterns) {
        const pattern = patterns[type];
        if (pattern.test(value)) {
            if (type === "staticCost" || type === "users" || type === "numberOnly") {
                return value.replace(`${REGEX.NUMBER}/g`, "-");
            } else if (type === "alphabetsWithSpecialCharacter") {
                return value.replace(`${REGEX.ALPHANUMERIC_WITH_SPECIAL_CHARACTER}/g`, "_");
            } else if (type === "phoneNumber") {
                return value.replace(/D/g, "");
            }
            return value;
        }
    } else if (/^[0-9 \b]+$/.test(value)) {
        return value.replace(/^0+/, "");
    }
};

export const isObjectValuesEmpty = (obj) =>
    Object.values(obj).some((x) => x === null || x === "" || x?.length === 0);

export const objectKeyContainsValues = (obj) =>
    Object.values(obj).some(
        (x) => x !== null && x !== "" && typeof x !== "undefined"
    );

export const omit = (targetObj = {}, props = []) => {
    const obj = {...targetObj};
    if (!Array.isArray(props)) return;

    props.forEach((prop) => {
        obj.hasOwnProperty(prop) && delete obj[prop];
    });

    return obj;
};

export const materialUiColor = {
    normalTextColor: "#1e3a4f",
    yellowColor: "#efc415",
    grayColor: "#cbd2d7",
    gray: "#9EAAB3",
    textColorDark: "#718390",
    white: "#fff",
    redColor: "#ff6363",
    grayMedium: "#375062",
    lightGreen: "#88D08B",
    darkGreen: "#88d08b33",
    darkRed: "#ff636333",
    darkYellow: "#f5c64433",
    lightGray: "#1e3a4f33",
    lightSkyBlue: "#E2EDF8",
    gray_01: "#61646B",
};

export const noOp = () => undefined;

export const isObjectEmpty = (obj = {}) =>
    !obj || Object.keys(obj).length === 0;

export const isNotEmptyArray = (arr) => Array.isArray(arr) && arr.length > 0;

export const parseValue = (num) => +num.toFixed(1);
export const parseValueAPI = (num) => +num.toFixed(0);

export const colorArray = [
    "#d1dbe7",
    "#efc415",
    "#f59a00",
    "#eb5c64",
    "#009766",
    "#96BFDE",
    "#A794C6",
];

export const isMultipleOrder = (payload) => {
    if (payload.length === 1) {
        return false;
    }
    let totalNonZeroOrder = 0;
    // eslint-disable-next-line array-callback-return
    payload.map((data) => {
        if (data.value > 0) {
            totalNonZeroOrder = totalNonZeroOrder + 1;
        }
    });
    return totalNonZeroOrder > 1;
};

export const sumOfValues = (payload) => {
    return payload?.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.value;
    }, 0);
};
export const getUserRoles = (userDetails) => {
    if (userDetails?.roles?.length !== 0) {
        return userDetails?.roles.map((obj) => obj.name).join(", ");
    } else {
        return NO_USER_ROLE_MESSAGE;
    }
}
export const getUserCompaniesName = (userDetails) => {
    if (userDetails?.companies?.length !== 0) {
        return userDetails?.companies.map((obj) => obj.name).join(", ");
    } else {
        return PROFILE_NO_COMPANY_MESSAGE;
    }
}
export const getUserPermissions = (roles) => {
    return roles?.reduce((merged, roles) => {
        roles?.permissions?.forEach(permission => {
            if (!merged.includes(permission)) {
                merged.push(permission);
            }
        });
        return merged;
    }, []);
}
export const getAllowedOrderType = (companies) => {
    return companies?.reduce((merged, companies) => {
        companies?.allowedOrderTypes?.forEach(orderType => {
            if (!merged.includes(orderType)) {
                merged.push(orderType);
            }
        });
        return merged;
    }, []);
}
export const getOrderPath = (userDetails) => {
    const allowedOrderTypes = getAllowedOrderType(userDetails?.companies);

    if (allowedOrderTypes?.includes(ORDER_TYPE.INDUSTRY_OFFTAKE) && allowedOrderTypes?.includes(ORDER_TYPE.TRAILER_BAY)) {
        return ORDER_PAGE.ORDER_TYPE;
    } else if (allowedOrderTypes?.includes(ORDER_TYPE.TRAILER_BAY)) {
        return ORDER_PAGE.ORDER_FLEXIBLE;
    } else if (allowedOrderTypes?.includes(ORDER_TYPE.INDUSTRY_OFFTAKE)) {
        return ORDER_PAGE.ORDER;
    } else {
        return ORDER_PAGE.ORDER_HISTORY
    }
};

export const getOptions = (optionArray) => {
    return optionArray.map((itm) => ({
        label: itm?.name,
        value: itm?.name,
    }));
};

export const getOptionsWithIds = (optionArray) => {
    return optionArray.map((itm) => ({
        label: itm?.name,
        value: itm?.id,
    }));
};
export const parseJwt = (token) => {
    if (token) {
        const base64Url = token?.split('.')[1];
        const base64 = base64Url?.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }
}

export const getErrorMessage = (error, entityName) => {
    if (error === ErrorCodes.NotFound) {
        switch (entityName) {
            case "Profile":
                return ErrorMessage.PROFILE_NOT_FOUND_ERROR_MESSAGE;
            case "orderHistory" :
                return ErrorMessage.ORDER_HISTORY_NOT_FOUND_MESSAGE;
            case "productionPlan" :
                return ErrorMessage.PRODUCTION_PLAN_NOT_FOUND_MESSAGE;
            case "settings" :
                return ErrorMessage.SETTINGS_NOT_FOUND_MESSAGE;
            case "flexibleDemandSummary" :
                return ErrorMessage.FLEXIBLE_DEMAND_SUMMARY_NOT_FOUND_MESSAGE;
            case "orderDetails" :
                return ErrorMessage.ORDER_DETAILS_NOT_FOUND_MESSAGE;
            case "editOrder" :
                return ErrorMessage.EDIT_ORDER_NOT_FOUND_MESSAGE;
            case "staticCosts" :
                return ErrorMessage.STATIC_COST_NOT_FOUND_MESSAGE;
            case "user" :
                return ErrorMessage.USER_NOT_FOUND_MESSAGE;
            case "companies" :
                return ErrorMessage.COMPANIES_NOT_FOUND_MESSAGE;
            case "maintenance" :
                return ErrorMessage.MAINTENANCE_NOT_FOUND_MESSAGE;
            case "approval" :
                return ErrorMessage.ORDER_APPROVAL_NOT_FOUND_MESSAGE;
            default :
                return ErrorMessage.DEFAULT;
        }
    } else if (error === ErrorCodes.AccessDenied) {
        return ErrorMessage.ACCESS_DENIED;
    } else if (error === ErrorCodes.UncaughtException) {
        return ErrorMessage.UNCAUGHT_EXCEPTION;
    } else {
        return error || ErrorMessage.UNCAUGHT_EXCEPTION;
    }
}

export const errorHandler = (error, navigate, dispatch, signIn, signOut, entityName) => {
    if (error?.message === ErrorCodes.LoginRequired) {
        signIn();
    } else if (error?.message === ErrorCodes.LogOutRequired) {
        signOut();
    } else {
        dispatch(
            setNetworkErrorMessage({
                errorMessage: getErrorMessage(error?.message, entityName),
            })
        );
        navigate(ERROR_PAGE);
    }
}
export const getMaxProductionValue = (settings) => {
    return settings?.filter((itm) => itm.name === "max_dispense_amount_per_hour")[0]?.value;
}
export const splitArrayWithOneDayRange = (array, startDate) => {
    const finalArray = [];
    let data = [];
    const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000 - (5 * 60000);
    let dayEndTime = startDate + ONE_DAY_IN_MILLISECONDS;
    array.forEach((item, index) => {
        if (item.time >= dayEndTime || index === array.length - 1) {
            finalArray.push({
                dayNumber: finalArray.length,
                data: [...data, item],
            });
            data = [];
            dayEndTime += ONE_DAY_IN_MILLISECONDS;
        } else {
            data.push(item);
        }
    });
    return finalArray;
};

export const hasPermission = (userRolePermission, permissionArray) => {
    return permissionArray.some((itm) => userRolePermission?.includes(itm));
}

export const isEditActionEnable = (status, row) => {
    return isPONumberEditAllowed(status, row) || isOrderAllowedToEdit(status, row);
}
export const isPONumberEditAllowed = (status, row) => {
    return !!((status === STATUS.PLANNED_LOCKED ||
            status === STATUS.IN_PRODUCTION ||
            status === STATUS.WAITING_FOR_APPROVAL ||
            status === STATUS.DELIVERED ||
            status === STATUS.COST_ADDED ||
            status === STATUS.ON_HOLD ||
            status === STATUS.ERROR) &&
        row?.demandType !== ORDER_TYPE.TRAILER_BAY);
};

export const isOrderAllowedToEdit = (status, row) => {
    return !!(status !== STATUS.CANCELLED &&
        !row?.isLocked);
}
export const customSort = (a, b) => {
    const statusOrder = {
        [STATUS.IN_PRODUCTION]: 1,
        [STATUS.PLANNED_LOCKED]: 2,
    };
    const statusA = statusOrder[a.status] || Number.MAX_SAFE_INTEGER;
    const statusB = statusOrder[b.status] || Number.MAX_SAFE_INTEGER;
    return statusA - statusB;
};

export const updateIndicator = (data) => {
    if (data) {
        const timeDifference = getUTCMinuteDifferenceForBRP(data.lastFetchedTime);
        let color;
        if (timeDifference < 15) {
            color = COLOR_INDICATOR.GREEN;
        } else if (timeDifference < 30) {
            color = COLOR_INDICATOR.YELLOW;
        } else {
            color = COLOR_INDICATOR.RED;
        }
        return color;
    }
};

export const formatNumber = (number) => {
    return number?.toFixed(2);
};

export const formatValueWithUnit = (value, unit) => {
    return `${value} ${unit}`;
}

export const notificationNavigation = async (navigate, notificationObject) => {
    switch (notificationObject.type) {

        case NOTIFICATION_TYPE.MAINTENANCE_CREATED:
        case NOTIFICATION_TYPE.MAINTENANCE_UPDATED:
        case NOTIFICATION_TYPE.MAINTENANCE_DELETED:
            return navigate(SETTINGS_PAGE);

        case NOTIFICATION_TYPE.BRP_PLAN_CHANGED:
            return navigate(PRODUCTION_PLAN_PAGE);

        default:
            return navigate(ORDER_PAGE.ORDER_HISTORY);
    }
}